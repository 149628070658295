import React from "react";
import { useState } from "react";
import styles from "./FAQCardContainer.module.css";
export const FAQCardContainer = () => {
  const faqs = [
    {
      question: "How can PlloHide benefit my business?",
      answer:
        "PlloHide offers numerous advantages for businesses within the leather industry. By providing access to verified suppliers and manufacturers, our platform ensures transparency, reliability, and sustainability throughout the production process. Whether you're a designer looking for materials or a manufacturer seeking new collaborations, PlloHide streamlines operations and fosters growth opportunities.",
    },
    {
      question: "Is PlloHide suitable for small businesses and independent designers?",
      answer:
        "Absolutely! PlloHide is designed to support businesses of all sizes, including small-scale operations and independent designers. Our platform provides a level playing field, offering access to a diverse range of materials, manufacturing partners, and collaborative opportunities. Whether you're just starting out or looking to expand your business, PlloHide has resources tailored to your needs.",
    },
    {
      question: "How does PlloHide ensure the quality and authenticity of materials and suppliers?",
      answer:
        "At PlloHide, we take quality and authenticity seriously. We carefully vet all suppliers and manufacturers to ensure they meet our stringent standards for ethical practices, craftsmanship, and product quality. Additionally, our platform incorporates user reviews and ratings, providing valuable feedback to help users make informed decisions when sourcing materials or partnering with suppliers.",
    },
    {
      question: "Can I find suppliers and manufacturers for my designs?",
      answer:
        "We provide a curated marketplace where designers can discover verified suppliers and manufacturers who share their commitment to quality and sustainability.",
    }
  ];

  const [activeIndex, setActiveIndex] = useState(null);

  const toggleActive = (index) => {
    setActiveIndex((prevIndex) => (prevIndex === index ? null : index));
  };

  return (
    <div className={styles.fAQCardContainer}>
      {faqs.map((faq, index) => (
        <div
          key={index}
          className={`${styles.faqCard} ${
            activeIndex === index ? styles.active : ""
          }`}
          onClick={() => toggleActive(index)}
        >
          <div className={styles.title}>
            <h3 className={styles.question}>{faq.question}</h3>
            <img
              className={styles.icon}
              // src={activeIndex === index ? "/remove.svg" : "/add.svg"}
              src={activeIndex === index ? require("../public/remove.svg") : require("../public/add.svg")}
              alt=""
            />
          </div>
          {activeIndex === index && (
            <div className={styles.answer}>{faq.answer}</div>
          )}
        </div>
      ))}
    </div>
  );
};
