import React, { useEffect } from 'react';
import { Button } from "@mui/material";
import { Link, NavLink } from "react-router-dom";
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import LoginBg from '../../assets/images/bg.png';
import OfflinePin from '../../assets/images/OfflinePin.png';
import Logo from '../../assets/images/logo_w.svg';
import User from '../../assets/images/user.png';
import Dropdown from 'react-bootstrap/Dropdown';
import { useCookies } from "../../hooks/useCookies";
export const SupplierProfileSuccessfully = () => {
    const [isLogin, setIsLogin] = React.useState(false);
  const cookies = useCookies();
  const userName = cookies?.getCookie("userName");
 console.log(userName);
  const [profileURL, setProfileURL] = React.useState("");
  useEffect(() => {
    if (cookies?.getCookie("userId")) {
      setIsLogin(true);
    }

    const accountType = cookies.getCookie("accountType");

    if (accountType === "manufacturer") {
      setProfileURL("/ManufacturerProfile");
    } else if (accountType === "designer") {
      setProfileURL("/DesignerProfile/TheProfile");
    } else if (accountType === "supplier") {
      setProfileURL("/SupplierProfile");
    }
  }, [cookies]);
  const navigateTo = (url) => {
    window.location.href = url;
  };
    return (
        <>
            <header className="header_top succes_hd">
                <Navbar expand="lg" className="navbar navbar-expand-lg">
                    <Container>
                    <Link to="/Community">
                <Navbar.Brand to="/Community">
                  <img src={Logo} />
                </Navbar.Brand>
                </Link>
                        <Navbar.Toggle aria-controls="basic-navbar-nav" />
                        <Navbar.Collapse id="basic-navbar-nav">
                            <Nav className="me-auto">
                                {/* <Nav.Link href="#home">Home</Nav.Link>
                            <Nav.Link href="#link">Link</Nav.Link> */}

                            </Nav>
                            <Dropdown className='user_login'>
                                <Dropdown.Toggle id="dropdown-basic" className='btn'>
                                    <img src={User} />
                                </Dropdown.Toggle>

                                <Dropdown.Menu>
                      <Dropdown.Item href={profileURL}>
                        My Profile
                      </Dropdown.Item>
                      
                      <Dropdown.Item onClick={
                        () => {
                          cookies.deleteCookie("userId");
                          navigateTo("/Login");
                        }
                      }>Logout</Dropdown.Item>
                    </Dropdown.Menu>
                            </Dropdown>
                        </Navbar.Collapse>
                    </Container>
                </Navbar>
            </header>

            <div className="signup_page">
                <div className="bg_inner">
                    <img src={LoginBg} className="media_img" />
                    <div className="card_success">
                        <div className="bg_inner">
                            <img src={OfflinePin} />
                            <h3>Your Payment has been successfully processed.</h3>
                            <p>Thank you for choosing pllohide.</p>
                        </div>

                    </div>
                </div>
            </div>
        </>
    );
};
