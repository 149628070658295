import React, { useMemo } from "react";
import styles from "./SocialMediaLogos.module.css";

export const SocialMediaLogos = ({
  closeUpOfAShoemakerMeasur,
  enhancedCollaboration,
  connectDesignersSuppliers,
  propWidth,
  propHeight,
  propPadding,
  propHeight1,
}) => {
  const closeUpOfAShoemakerMeasurStyle = useMemo(() => {
    return {
      width: propWidth,
      height: propHeight,
    };
  }, [propWidth, propHeight]);
  const frameDiv1Style = useMemo(() => {
    return {
      padding: propPadding,
    };
  }, [propPadding]);

  const connectDesignersSuppliersStyle = useMemo(() => {
    return {
      height: propHeight1,
    };
  }, [propHeight1]);
  return (
    <div className={styles.socialMediaLogos}>
      <div className={styles.photo}>
        <div className={styles.bg} />
        <img
          className={styles.closeUpOfAShoemakerMeasur}
          loading="lazy"
          alt=""
          src={closeUpOfAShoemakerMeasur}
          style={closeUpOfAShoemakerMeasurStyle}
        />
      </div>
      <div className={styles.rectangleParent} style={frameDiv1Style}>
        <div className={styles.frameChild} />
        <h3 className={styles.enhancedCollaboration}>
          {enhancedCollaboration}
        </h3>
        <div
          className={styles.connectDesignersSuppliers}
          style={connectDesignersSuppliersStyle}
        >
          {connectDesignersSuppliers}
        </div>
      </div>
    </div>
  );
};
