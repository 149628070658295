import React, { useEffect } from "react";
import styles from "./Navbar.module.css";
import { useCookies } from "../../../../hooks/useCookies";
import Container from "react-bootstrap/Container";
import Logo from "../../../../assets/images/logo.png";
import Nav from "react-bootstrap/Nav";
import Dropdown from "react-bootstrap/Dropdown";
import Navbar from "react-bootstrap/Navbar";
import { Link } from "react-router-dom";

export const CommunityNavbar = () => {
  const [isLogin, setIsLogin] = React.useState(false);
  const cookies = useCookies();
  const userName = cookies?.getCookie("userName");
 console.log(userName);
  const [profileURL, setProfileURL] = React.useState("");
  useEffect(() => {
    if (cookies?.getCookie("userId")) {
      setIsLogin(true);
    }

    const accountType = cookies.getCookie("accountType");

    if (accountType === "manufacturer") {
      setProfileURL("/ManufacturerProfile");
    } else if (accountType === "designer") {
      setProfileURL("/DesignerProfile/TheProfile");
    } else if (accountType === "supplier") {
      setProfileURL("/SupplierProfile");
    }
  }, [cookies]);
  const navigateTo = (url) => {
    window.location.href = url;
  };
  return (
    // <header className={styles.rectangleParent}>
    //   <div className={styles.frameChild} />
    //   <div className={styles.divelementorbackgroundoverlay}>
    //     <img
    //       className={styles.headingIcon}
    //       loading="lazy"
    //       alt=""
    //       src={require("../public/22@2x.png")}
    //     />
    //     <div className={styles.reduceCarbonFootprint}>
    //       <div className={styles.fAQCards}>
    //         <div className={styles.aboutUs}>About Us</div>
    //         <div className={styles.howItWorks}>How It Works?</div>
    //         <div className={styles.ourFeature}>Our Feature</div>
    //         <div onClick={
    //           () => {
    //             navigateTo("/Community/ArticleList")
    //           }
    //         } className={styles.community1}>Community</div>
    //         <div className={styles.team}>Team</div>
    //         <div className={styles.faq}>FAQ</div>
    //       </div>
    //     </div>
    //     <button className={styles.getStarted}>
    //       <div onClick={
    //           () => {
    //             navigateTo("/Login")
    //           }
    //         } className={styles.logIn}>Log In</div>
    //     </button>
    //   </div>
    // </header>
    // <div className={styles.rectangleParent}>
    //   new header
    // </div>
    <span>
      {isLogin ? (
        <header className="header_top">
          <Navbar expand="lg" className="navbar navbar-expand-lg">
            <Container>
              <Navbar.Brand to="/SearchManufacturer">
                <img src={Logo} />
              </Navbar.Brand>
              
              <Navbar.Toggle aria-controls="basic-navbar-nav" />
              <Navbar.Collapse id="basic-navbar-nav">
                <Nav className="me-auto">
                  <Dropdown className="user_login">
                    <Dropdown.Toggle id="dropdown-basic" className="btn">
                      <span>Find Professionals</span>
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      <Dropdown.Item onClick={
                        () => {
                          navigateTo("/Find-professionals")
                        }
                      }>
                        Manufacturer
                      </Dropdown.Item>
                      <Dropdown.Item onClick={
                        () => {
                          navigateTo("/Find-professionals/Designer")
                        }
                      }>
                        Designer
                      </Dropdown.Item>
                      <Dropdown.Item onClick={
                        () => {
                          navigateTo("/Find-professionals/Supplier")
                        }
                      }>
                        Supplier
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                  <Nav.Link style={{display :"flex", alignItems: "center"}} onClick={
                    () => {
                      navigateTo("/Community/ArticleList")
                    }
                  }>Community </Nav.Link>
                </Nav>
                <div className="btn_grp">
                  <div className="search_bar">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Search"
                    />
                    <span className="icon" style={{marginLeft : "-80%", marginTop: "-20px"}}>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="31"
                        height="30"
                        viewBox="0 0 31 30"
                        fill="none"
                      >
                        <g clip-path="url(#clip0_15819_25)">
                          <mask
                            id="mask0_15819_25"
                            maskUnits="userSpaceOnUse"
                            x="0"
                            y="0"
                            width="31"
                            height="30"
                          >
                            <path
                              d="M30.1636 0H0.163574V30H30.1636V0Z"
                              fill="white"
                            />
                          </mask>
                          <g mask="url(#mask0_15819_25)">
                            <path
                              d="M13.2886 21.25C17.7759 21.25 21.4136 17.6123 21.4136 13.125C21.4136 8.63769 17.7759 5 13.2886 5C8.80126 5 5.16357 8.63769 5.16357 13.125C5.16357 17.6123 8.80126 21.25 13.2886 21.25Z"
                              stroke="#C0C0C0"
                              stroke-width="1.5"
                              stroke-linejoin="round"
                            />
                            <path
                              d="M24.7216 25.442C24.9657 25.686 25.3614 25.686 25.6056 25.442C25.8496 25.1979 25.8496 24.8021 25.6056 24.558L24.7216 25.442ZM25.6056 24.558L19.3556 18.308L18.4716 19.192L24.7216 25.442L25.6056 24.558Z"
                              fill="#C0C0C0"
                            />
                          </g>
                        </g>
                        <defs>
                          <clipPath id="clip0_15819_25">
                            <rect
                              width="30"
                              height="30"
                              fill="white"
                              transform="translate(0.163574)"
                            />
                          </clipPath>
                        </defs>
                      </svg>
                    </span>
                  </div>


                  <Dropdown className="user_login">
                    <Dropdown.Toggle id="dropdown-basic" className="btn">
                      <span>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="32"
                          height="32"
                          viewBox="0 0 32 32"
                          fill="none"
                        >
                          <path
                            d="M21.3333 13.3333C21.3333 14.7478 20.7714 16.1044 19.7712 17.1046C18.771 18.1048 17.4145 18.6667 16 18.6667C14.5855 18.6667 13.2289 18.1048 12.2288 17.1046C11.2286 16.1044 10.6667 14.7478 10.6667 13.3333C10.6667 11.9188 11.2286 10.5623 12.2288 9.5621C13.2289 8.5619 14.5855 8 16 8C17.4145 8 18.771 8.5619 19.7712 9.5621C20.7714 10.5623 21.3333 11.9188 21.3333 13.3333Z"
                            fill="#C0C0C0"
                          />
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M15.456 29.3226C8.34466 29.0373 2.66666 23.1813 2.66666 16C2.66666 8.63596 8.63599 2.66663 16 2.66663C23.364 2.66663 29.3333 8.63596 29.3333 16C29.3333 23.364 23.364 29.3333 16 29.3333C15.9391 29.3337 15.8782 29.3337 15.8173 29.3333C15.6967 29.3333 15.576 29.3293 15.456 29.3226ZM7.44399 24.4133C7.3443 24.127 7.31037 23.8219 7.34471 23.5208C7.37905 23.2196 7.48079 22.93 7.64237 22.6735C7.80396 22.417 8.02123 22.2002 8.27807 22.0391C8.53491 21.8781 8.82472 21.777 9.12599 21.7433C14.3233 21.168 17.7087 21.22 22.8807 21.7553C23.1823 21.7867 23.4728 21.8866 23.7301 22.0472C23.9874 22.2079 24.2046 22.4251 24.3651 22.6824C24.5257 22.9397 24.6255 23.2303 24.6569 23.532C24.6882 23.8336 24.6503 24.1385 24.546 24.4233C26.7626 22.1806 28.004 19.1533 28 16C28 9.37263 22.6273 3.99996 16 3.99996C9.37266 3.99996 3.99999 9.37263 3.99999 16C3.99999 19.2773 5.31399 22.248 7.44399 24.4133Z"
                            fill="#C0C0C0"
                          />
                        </svg>
                      </span>
                      <span> {userName}</span>
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      <Dropdown.Item href={profileURL}>
                        My Profile
                      </Dropdown.Item>

                      <Dropdown.Item onClick={
                        () => {
                          cookies.deleteCookie("userId");
                          navigateTo("/Login");
                        }
                      }>Logout</Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </Navbar.Collapse>
            </Container>
          </Navbar>
        </header>
      ) : (
        <header className={styles.rectangleParent}>
          <div className={styles.frameChild} />
          <div className={styles.divelementorbackgroundoverlay}>
            <img
              className={styles.headingIcon}
              loading="lazy"
              alt=""
              src={require("../public/22@2x.png")}
            />
            <div className={styles.reduceCarbonFootprint}>
              <div className={styles.fAQCards}>
                <div className={styles.aboutUs}>About Us</div>
                <div className={styles.howItWorks}>How It Works?</div>
                <div className={styles.ourFeature}>Our Feature</div>
                <div
                  onClick={() => {
                    navigateTo("/Community/ArticleList");
                  }}
                  className={styles.community1}
                >
                  Community
                </div>
                <div className={styles.team}>Team</div>
                <div className={styles.faq}>FAQ</div>
              </div>
            </div>
            <button className={styles.getStarted}>
  {isLogin ? (
    <div>
      {userName}
    </div>
  ) : (
    <div
      onClick={() => {
        navigateTo("/Login");
      }}
      className={styles.logIn}
    >
      Log In
    </div>
  )}
</button>



          </div>
        </header>
      )}
    </span>
    // <div>
    //   hello
    // </div>
  );
};
