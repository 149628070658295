
import React from 'react'
import styles from "./MaskGroup.module.css";
import {CommunityNavbar} from "./CommunityNavbar";

export const MaskGroup = () => {
    return (
    <section className={styles.maskGroup}>
      <div className={styles.untitledDesign341Parent}>
        <img
          className={styles.untitledDesign341}
          alt=""
          // src="/untitled-design-34-1@2x.png"
          src={require("../public/untitled-design-34-1@2x.png")}
        />
        <div className={styles.community} />
        <CommunityNavbar />
        <div className={styles.ourService}>
          <h1 className={styles.redefiningLeatherCraftsmansh}>
            Redefining Leather Craftsmanship
          </h1>
          <h3 className={styles.yourJourneyBegins}>
            Your Journey Begins with PlloHide
          </h3>
        </div>
      </div>
    </section>
  );
};
