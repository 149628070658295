import React from 'react'
import {FrameComponent} from "./FrameComponent";
import {ButtonWrapper} from "./ButtonWrapper";
import styles from "./Heading.module.css";
import underline from "../public/underline-1.svg";
import connectSvgrepocom from "../public/connect-svgrepocom.svg";
import collaborateSvgrepocom from "../public/collaborate-svgrepocom.svg";
import createSvgrepocom from "../public/create-svgrepocom.svg";
import poweroffSvgrepocom from "../public/poweroff-svgrepocom.svg";
export const Heading = () => {
    return (
    <div className={styles.heading}>
      <div className={styles.text}>
        <FrameComponent
          howItWorks="How It Works?"
          underline={underline}
          propWidth={400}
        />
        <ButtonWrapper
          connectSvgrepocom={connectSvgrepocom}
          heading3SaveMoreEnergy="Connect"
          joinADynamicCommunityOfIn="Join a dynamic community of industry professionals united by a shared passion for innovation and sustainability."
        />
        <ButtonWrapper
          connectSvgrepocom={collaborateSvgrepocom}
          heading3SaveMoreEnergy="Collaborate"
          joinADynamicCommunityOfIn="Share ideas, access a diverse range of materials, and collaborate on projects with ease."
        />
        <ButtonWrapper
          connectSvgrepocom={createSvgrepocom}
          heading3SaveMoreEnergy="Create"
          joinADynamicCommunityOfIn="Harness the power of our intuitive tools and resources to bring your creative vision to life."
        />
        <ButtonWrapper
          connectSvgrepocom={poweroffSvgrepocom}
          heading3SaveMoreEnergy="Empower"
          joinADynamicCommunityOfIn="Empower your business to thrive with unparalleled efficiency, creativity, and sustainability."
        />
      </div>
      <div className={styles.text1}>
        <div className={styles.group}>
          <div className={styles.photo}>
            <div className={styles.bg} />
            <img
              className={styles.closeUpOnEngravingArtTool}
              alt=""
              src={require("../public/close-up-on-engraving-art-tools@2x.png")}
            />
          </div>
          <div className={styles.photo1}>
            <div className={styles.bg1} />
            <img
              className={styles.maleDesignerAndLeatherTail}
              loading="lazy"
              alt=""
              src={require("../public/male-designer-and-leather-tailor-working-at-a-factory-close-up@2x.png")}
            />
          </div>
        </div>
        <div className={styles.photo2}>
          <div className={styles.designHub}>
            <div className={styles.bg2} />
            <img
              className={styles.manInAStudioCreatesLeathe}
              loading="lazy"
              alt=""
              src={require("../public/man-in-a-studio-creates-leather-ware@2x.png")}
            />
          </div>
        </div>
      </div>
    </div>
  );
};