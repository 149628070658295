import React, { useEffect, useState } from 'react';
import { Container } from "react-bootstrap";
import Dropdown from 'react-bootstrap/Dropdown';
import Nav from 'react-bootstrap/Nav';
import { Link } from 'react-router-dom';
import Navbar from 'react-bootstrap/Navbar';
import InputMask from 'react-input-mask';
import check_vef from '../../assets/images/check_vef.png';
import crd_wif from '../../assets/images/crd_wif.png';
import Logo from '../../assets/images/logo.svg';
import master from '../../assets/images/master.png';
import User from '../../assets/images/user.png';
import { Footer } from '../Footer';
import { useCookies } from '../../hooks/useCookies';
import { API_URL } from '../../api';

export const PaymentView = () => {

    const [cardNumber, setCardNumber] = useState('');
    const [cardHolderName, setCardHoldeName] = useState('Card Holder name');
    const [ExpiryMonth, setExpiryMonth] = useState('MM');
    const [ExpiryYear, setExpiryYear] = useState('YY');
    const cookies = useCookies();
    const [amount, setAmount] = useState(0);
    const [isLogin, setIsLogin] = React.useState(false);

    const userName = cookies?.getCookie("userName");
   console.log(userName);
    const [profileURL, setProfileURL] = React.useState("");
    useEffect(() => {
      if (cookies?.getCookie("userId")) {
        setIsLogin(true);
      }
  
      const accountType = cookies.getCookie("accountType");
  
      if (accountType === "manufacturer") {
        setProfileURL("/ManufacturerProfile");
      } else if (accountType === "designer") {
        setProfileURL("/DesignerProfile/TheProfile");
      } else if (accountType === "supplier") {
        setProfileURL("/SupplierProfile");
      }
    }, [cookies]);
    const navigateTo = (url) => {
      window.location.href = url;
    };
    useEffect(() => {
        async function getPaymentAmount() {
            const response = await fetch(`${API_URL}/designer/payment/amount/${cookies.getCookie('paymentId')}`);
            const data = await response.json();
            setAmount(data.amount);
        }
        getPaymentAmount();
    }, []);

    const handleExpiryMonth = (e) => {
        const expiryM = e.target.value;

        setExpiryMonth(expiryM);
    };
    const handleExpiryYear = (e) => {
        const expiryY = e.target.value;

        setExpiryYear(expiryY);
    };

    const handleCardNameChange = (e) => {
        const name = e.target.value;

        setCardHoldeName(name);
    };

    const handleCardNumberChange = (e) => {
        const input = e.target.value;
        let maskedInput = '';


        for (let i = 0; i < input.length; i++) {

            if (/\d/.test(input[i]) && i < 14) {

                maskedInput += '*';
            }
            else {
                maskedInput += input[i];
            }


        }

        setCardNumber(maskedInput);
    };

    async function confirmPayment() {
        const response = await fetch(`${API_URL}/designer/payment/confirm/${cookies.getCookie("paymentId")}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                amount: amount,
            }),
        });

        const data = await response.json();

        if (response.ok) {
            window.location.href = '/DesignerProfile/Orders';
        }
    }

    return (
        <>
            <header className="header_top">
                <Navbar expand="lg" className="navbar navbar-expand-lg">
                    <Container>
                    <Link to="/Community">
                <Navbar.Brand to="/Community">
                  <img src={Logo} />
                </Navbar.Brand>
                </Link>
                        <Navbar.Toggle aria-controls="basic-navbar-nav" />
                        <Navbar.Collapse id="basic-navbar-nav">
                            <Nav className="me-auto">
                                {/* <Nav.Link href="#home">Home</Nav.Link>
                            <Nav.Link href="#link">Link</Nav.Link> */}

                            </Nav>
                            <Dropdown className='user_login'>
                                <Dropdown.Toggle id="dropdown-basic" className='btn'>
                                    <img src={User} />
                                </Dropdown.Toggle>

                                <Dropdown.Menu>
                      <Dropdown.Item href={profileURL}>
                        My Profile
                      </Dropdown.Item>
                      
                      <Dropdown.Item onClick={
                        () => {
                          cookies.deleteCookie("userId");
                          navigateTo("/Login");
                        }
                      }>Logout</Dropdown.Item>
                    </Dropdown.Menu>
                            </Dropdown>
                        </Navbar.Collapse>
                    </Container>
                </Navbar>
            </header>
            <div className="Subscrip_sect invdi_sect">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="box_plan">
                                <h3>Payment Method</h3>
                                <p className="brd_underline">Select your payment method and enter your payment information.</p>
                                <div className="row">
                                    <div className="col-lg-7 col-md-12 col-sm-12">
                                        <div className="credit_card_bx mt-3">
                                            <ul>
                                                <li><span className="fa fa-credit-card"></span> net banking </li>
                                                <li><span className="fa fa-credit-card"></span> credit/debit card
                                                </li>
                                                <li><span className="fa fa-credit-card"></span> Add New Card </li>
                                            </ul>
                                            <h3>Card Number</h3>
                                            <div className="frm_grp">
                                                <label className="label_nrm" for="">Enter the 16- digit card number</label>
                                                <InputMask
                                                    mask="9999 9999 9999 9999"
                                                    type="text"
                                                    placeholder="xxxx xxxx xxxx xxxx"
                                                    className="form-control "
                                                    //  required 
                                                    onChange={handleCardNumberChange}></InputMask>

                                                <span className="icon_bx">
                                                    <img src={master} alt="" />
                                                    <img src={check_vef} alt="" />

                                                </span>
                                            </div>
                                            <h3>Card Holder Name</h3>
                                            <div className="frm_grp">
                                                <label className="label_nrm" for="">Enter Card holder name </label>
                                                <input type="text" placeholder="Maria Barrymore" className="form-control" onChange={handleCardNameChange} required />
                                                <span className="icon_bx">
                                                    <img src={check_vef} alt="" /></span>
                                            </div>
                                            <div className="grp_sect mb-3">
                                                <div className="lft_bx">
                                                    <h3>CVV Number</h3>
                                                    <label className="label_nrm" for="">Enter the 3 digit number</label>
                                                </div>
                                                <div className="lft_bx">
                                                    <input type="text" className="form-control" maxLength="3" required />
                                                </div>
                                            </div>
                                            <div className="grp_sect">
                                                <div className="lft_bx">
                                                    <h3>Expiry Date</h3>
                                                    <label className="label_nrm" for="">Enter the Expiration date of the card</label>
                                                </div>
                                                <div className="rgt_bx">
                                                    <input type="text" className="form-control" placeholder="MM" maxLength="2" onChange={handleExpiryMonth} required />
                                                    <span>/</span>
                                                    <input type="text" className="form-control" placeholder="YY" maxLength="2" onChange={handleExpiryYear} required />
                                                </div>
                                            </div>
                                            <div className="btn_grp_com">
                                                <button className="btn bg_btn" onClick={confirmPayment}>Confirm and Pay</button>
                                            </div>
                                        </div>

                                    </div>
                                    <div className="col-lg-5 col-md-12 col-sm-12">
                                        <div className="paym_card">

                                            <div className="crd_details">
                                                <div className="crd_img_wif">
                                                    <img src={crd_wif} alt="" />
                                                </div>
                                                {/* <h3>Card Holder name</h3> */}
                                                <h3>{cardHolderName}</h3>
                                                <p>{cardNumber}</p>
                                                <div className="crd_det">
                                                    {/* <h3>9/27</h3> */}
                                                    <h3>{ExpiryMonth}/{ExpiryYear}</h3>

                                                    <div className="crd_name ">
                                                        <img src={master} alt="" />
                                                        <p>Visa card</p>
                                                    </div>
                                                </div>

                                            </div>
                                            <p className="text">Amount to Pay</p>
                                            <div className="pay__bx">
                                                <p><span>CAD {amount}</span></p>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>

                </div>
            </div>

            <Footer></Footer>
        </>

    );
};
