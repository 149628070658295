import React from "react";
import styles from "./GroupComponent.module.css";
import phenvelopeFillIcon from "../public/phenvelopefill.svg";
import phphoneCallFillIcon from "../public/phphonecallfill.svg";
import phinstagramLogoFillIcon from "../public/phinstagramlogofill.svg";
import phmapPinLineFillIcon from "../public/phmappinlinefill.svg";
import phyoutubelogofill from "../public/phyoutubelogofill.svg";
import phtwitterlogofill from "../public/phtwitterlogofill.svg";
import phfacebooklogofill from "../public/phfacebooklogofill.svg";
export const GroupComponent = () => {
  return (
    <section className={styles.rectangleParent}>
      <div className={styles.frameChild} />
      <div className={styles.content}>
        <div className={styles.plantWateringAdvice}>
          <img
            className={styles.contentGroupIcon}
            alt=""
            src={require("../public/22@2x.png")}
          />
          <div className={styles.phenvelopeFillParent}>
            <img
              className={styles.phenvelopeFillIcon}
              loading="lazy"
              alt=""
              src={phenvelopeFillIcon}
            />
            <div className={styles.piiohidecompanyca}>ceo@pllohide.ca</div>
          </div>
          {/* <div className={styles.phphoneCallFillParent}>
            <img
              className={styles.phphoneCallFillIcon}
              loading="lazy"
              alt=""
              src={phphoneCallFillIcon}
            />
            <div className={styles.div}>(021) 876 347 294</div>
          </div> */}
          <div className={styles.phoneandEmailFrame}>
            <img
              className={styles.phmapPinLineFillIcon}
              loading="lazy"
              alt=""
              src={phmapPinLineFillIcon}
            />
            <div className={styles.ranchview7658764DrContainer}>
              <p className={styles.ranchview}>{`C/O KUNG, LO&JIA LLP, `}</p>
              <p className={styles.drRichardson}>2025 WILLINGDON AVENUE,</p>
              <p className={styles.california62639}>SUITE 900,BURNABY BC VSC 0J3</p>
              <p className={styles.california62639}>CANADA</p>
            </div>
          </div>
        </div>
        <div className={styles.ourServiceSection}>
          <h3 className={styles.ourService}>Our Service</h3>
          <div className={styles.aboutUsHowItWorksOurFeat}>
            <div className={styles.aboutUs}>About Us</div>
            <div className={styles.howItWorks}>How It Works?</div>
            <div className={styles.ourFeatures}>Our Features</div>
            <div className={styles.community}>Community</div>
          </div>
        </div>
        <div className={styles.moreLinksFrame}>
          <h3 className={styles.moreLinks}>More Links</h3>
          <div className={styles.pricingTeamFAQFrame}>
            <div className={styles.pricing}>Pricing</div>
            <div className={styles.team}>Team</div>
            <div className={styles.faq}>FAQ</div>
          </div>
        </div>
        <div className={styles.platformConnectLogo}>
          <h3 className={styles.platformConnect}>Platform Connect</h3>
          <div className={styles.platformLogos}>
            <div className={styles.ig}>
              <img
                className={styles.phinstagramLogoFillIcon}
                loading="lazy"
                alt=""
                src={phinstagramLogoFillIcon}
              />
            </div>
            <div className={styles.yt}>
              <img
                className={styles.phyoutubeLogoFillIcon}
                loading="lazy"
                alt=""
                src={phyoutubelogofill}
              />
            </div>
            <div className={styles.tw}>
              <img
                className={styles.phtwitterLogoFillIcon}
                loading="lazy"
                alt=""
                src={phtwitterlogofill}
              />
            </div>
            <div className={styles.fb}>
              <img
                className={styles.phfacebookLogoFillIcon}
                loading="lazy"
                alt=""
                src={phfacebooklogofill}
              />
            </div>
          </div>
        </div>
      </div>
      <footer className={styles.copyrightInfoWrapper}>
        <div className={styles.copyrightInfo}>
          <div className={styles.copyrightPllohideAll}>
            @ Copyright PlloHide. All Rights Reserved
          </div>
        </div>
      </footer>
    </section>
  );
};
