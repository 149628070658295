import React from "react";
import styles from "./Team.module.css";
import {FrameComponent} from "./FrameComponent";
import underline from "../public/underline-1.svg";
import founder from '../../../../assets/images/founder.png'

export const Founder = () => {
  return (
    <div className={styles.team} style={{marginBottom:"60px"}}>
      <div className={styles.photo}>
        <div className={styles.bg} />
        <img
          className={styles.leatherBagForTravelWithHe}
          loading="lazy"
          alt=""
          src={founder}
        />
      </div>
      <div className={styles.elementMargin}>
        <div className={styles.headerGroup}>
        <div className={styles.subscription}>
        <FrameComponent
          howItWorks="Our Founder"
          underline={underline}
          propWidth={400}
        />
        </div>
           <h5 className={styles.name} style={{fontWeight : "700"}}>Ming-li Pai (Leo pai)</h5>
          <img
            className={styles.underlineIcon}
            loading="lazy"
            alt=""
            src={require("../public/underline.svg")}
          />
        </div>
        <div className={styles.plloHideIsContainer}>
        <ul>
            <li>
            Experienced business professional in leather industry.Currently MD Assistant at Classic Product (Thailand).
            </li>
            <li>
            Proficient in sales strategy, operations, sustainability, and team management.
            </li>
            <li>
            Holds an MBA from National Cheng Kung University.
            </li>
            <li>
            Extensive global network including Michael Kors, Coach, Asics and Tommy Hilfiger.
            </li>
                     </ul>
        </div>
      </div>
    </div>
  );
};
