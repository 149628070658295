import React, { useState } from "react";

import { IoMdHeart } from "react-icons/io";
import { BiMessageRoundedDetail } from "react-icons/bi";
import { IoMdShareAlt } from "react-icons/io";
import { BsThreeDots } from "react-icons/bs";
import Styles from "./ArticleList.module.css";
// import { CiSearch } from "react-icons/ci";
import { Link, NavLink } from "react-router-dom";
import { Footer } from "../Footer";

import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";

import Logo from "../../assets/images/logo.svg";

import Dropdown from "react-bootstrap/Dropdown";
import axios from "axios";
import { API_URL } from "../../api";
export const ArticleList = () => {
  const array = [1, 2, 3, 4, 5];
  const navigateTo = (url) => {
    window.location.href = url;
  };
  const [articleList, setArticleList] = useState([]);
  const fetchArticleList = async () => {
    console.log("fetchArticleList");
    try {
      const response = await axios.get(
        `${API_URL}/post`
      );
      setArticleList(response.data);
    } catch (error) {
      console.error(error);
    }
  };
  useState(() => {
    fetchArticleList();
  }, []);
  return (
    <div>
      {/* <Header /> */}

      <div className="hero-articlelist">
        <div className="shade"></div>

        <div className="navigationbar">
          <header className="header_top header-box">
            <Navbar expand="lg" className="navbar navbar-expand-lg">
              <Container>
                <Navbar.Brand to="/Find-professionals/Manufacturer">
                  <img src={Logo} />
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                  <Nav className="me-auto">
                    <Dropdown className="user_login">
                      <Dropdown.Toggle id="dropdown-basic" className="btn">
                        <span>Find Professionals</span>
                      </Dropdown.Toggle>

                      <Dropdown.Menu>
                        <Dropdown.Item as={NavLink} to="/Find-professionals">
                          Find Manufacturers
                        </Dropdown.Item>
                        <Dropdown.Item
                          as={NavLink}
                          to="/Find-professionals/Designer"
                        >
                          Find designers
                        </Dropdown.Item>
                        <Dropdown.Item
                          as={NavLink}
                          to="/find-professionals/Supplier"
                        >
                          Find Suppliers
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                    <Nav.Link to="/Find-professionals/Manufacturer">
                      Community
                    </Nav.Link>
                  </Nav>
                  <div className="btn_grp">
                    <div className="search_bar">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Search"
                      />
                      <span className="icon">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="31"
                          height="30"
                          viewBox="0 0 31 30"
                          fill="none"
                        >
                          <g clip-path="url(#clip0_15819_25)">
                            <mask
                              id="mask0_15819_25"
                              maskUnits="userSpaceOnUse"
                              x="0"
                              y="0"
                              width="31"
                              height="30"
                            >
                              <path
                                d="M30.1636 0H0.163574V30H30.1636V0Z"
                                fill="white"
                              />
                            </mask>
                            <g mask="url(#mask0_15819_25)">
                              <path
                                d="M13.2886 21.25C17.7759 21.25 21.4136 17.6123 21.4136 13.125C21.4136 8.63769 17.7759 5 13.2886 5C8.80126 5 5.16357 8.63769 5.16357 13.125C5.16357 17.6123 8.80126 21.25 13.2886 21.25Z"
                                stroke="#C0C0C0"
                                stroke-width="1.5"
                                stroke-linejoin="round"
                              />
                              <path
                                d="M24.7216 25.442C24.9657 25.686 25.3614 25.686 25.6056 25.442C25.8496 25.1979 25.8496 24.8021 25.6056 24.558L24.7216 25.442ZM25.6056 24.558L19.3556 18.308L18.4716 19.192L24.7216 25.442L25.6056 24.558Z"
                                fill="#C0C0C0"
                              />
                            </g>
                          </g>
                          <defs>
                            <clipPath id="clip0_15819_25">
                              <rect
                                width="30"
                                height="30"
                                fill="white"
                                transform="translate(0.163574)"
                              />
                            </clipPath>
                          </defs>
                        </svg>
                      </span>
                    </div>
                    <Dropdown className="user_login">
                      <Dropdown.Toggle id="dropdown-basic" className="btn">
                        <span>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="32"
                            height="32"
                            viewBox="0 0 32 32"
                            fill="none"
                          >
                            <path
                              d="M21.3333 13.3333C21.3333 14.7478 20.7714 16.1044 19.7712 17.1046C18.771 18.1048 17.4145 18.6667 16 18.6667C14.5855 18.6667 13.2289 18.1048 12.2288 17.1046C11.2286 16.1044 10.6667 14.7478 10.6667 13.3333C10.6667 11.9188 11.2286 10.5623 12.2288 9.5621C13.2289 8.5619 14.5855 8 16 8C17.4145 8 18.771 8.5619 19.7712 9.5621C20.7714 10.5623 21.3333 11.9188 21.3333 13.3333Z"
                              fill="#C0C0C0"
                            />
                            <path
                              fill-rule="evenodd"
                              clip-rule="evenodd"
                              d="M15.456 29.3226C8.34466 29.0373 2.66666 23.1813 2.66666 16C2.66666 8.63596 8.63599 2.66663 16 2.66663C23.364 2.66663 29.3333 8.63596 29.3333 16C29.3333 23.364 23.364 29.3333 16 29.3333C15.9391 29.3337 15.8782 29.3337 15.8173 29.3333C15.6967 29.3333 15.576 29.3293 15.456 29.3226ZM7.44399 24.4133C7.3443 24.127 7.31037 23.8219 7.34471 23.5208C7.37905 23.2196 7.48079 22.93 7.64237 22.6735C7.80396 22.417 8.02123 22.2002 8.27807 22.0391C8.53491 21.8781 8.82472 21.777 9.12599 21.7433C14.3233 21.168 17.7087 21.22 22.8807 21.7553C23.1823 21.7867 23.4728 21.8866 23.7301 22.0472C23.9874 22.2079 24.2046 22.4251 24.3651 22.6824C24.5257 22.9397 24.6255 23.2303 24.6569 23.532C24.6882 23.8336 24.6503 24.1385 24.546 24.4233C26.7626 22.1806 28.004 19.1533 28 16C28 9.37263 22.6273 3.99996 16 3.99996C9.37266 3.99996 3.99999 9.37263 3.99999 16C3.99999 19.2773 5.31399 22.248 7.44399 24.4133Z"
                              fill="#C0C0C0"
                            />
                          </svg>
                        </span>
                        <span> Alex Cooper</span>
                      </Dropdown.Toggle>

                      <Dropdown.Menu>
                        <Dropdown.Item to="#">My Profile</Dropdown.Item>
                        <Dropdown.Item to="#">Logout</Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                </Navbar.Collapse>
              </Container>
            </Navbar>
          </header>
        </div>

        <div className="hero-text">
          <span className="line1">Get Matched With Manufacturer</span>
          <span className="line2">
            Find The Best Leather Manufacturer For Your Needs. Get Matched
            Quickly And Easily
          </span>
        </div>
      </div>

      <div className={`d-flex gap-5  ${Styles.articleListContainer}`}>
        <NewArticleList />
        <div className={Styles.articleContainer}>
          <div className={Styles.articleCardContainer}>
            <div onClick={() => {
              navigateTo("/Community/ViewPost");
            }} className={Styles.articleCard}>
              <div className={Styles.articleCardHeader}>
                <div className="d-flex gap-2">
                  <div className={Styles.articleCardImgContainer}>
                    <img
                      className={Styles.articleCardImg}
                      src={require("../../assets/images/Smart_looking teacher.png")}
                      alt="articleCardImg"
                    />
                  </div>
                  <div className={Styles.articleProfileContainer}>
                    <div className={Styles.nameContainer}>Aryan Brennan</div>
                    <div className={Styles.timeContainer}>45 minutes ago</div>
                  </div>
                </div>
                <div>
                  <BsThreeDots />
                </div>
              </div>
              <div className={Styles.articleContent}>
                What are few things to keep in mind while designing shoes with
                Split Leather ?
              </div>
              <div className={Styles.articleFooter}>
                <div
                  className={`${Styles.articleStatsContainer} d-flex gap-4 align-items-center`}
                >
                  <div
                    className={`${Styles.likesContainer} gap-2 d-flex align-items-center`}
                  >
                    <IoMdHeart
                      style={{
                        color: "#F65858",
                        width: "24px",
                        height: "24px",
                      }}
                    />
                    <div className={Styles.likesCount}>12</div>
                  </div>
                  <div
                    className={`${Styles.commentsContainer} gap-2 d-flex align-items-center`}
                  >
                    <BiMessageRoundedDetail
                      style={{ width: "24px", height: "24px" }}
                    />
                    <div className={Styles.commentsCount}>12</div>
                  </div>
                  <div
                    className={`${Styles.shareContainer} gap-2 d-flex align-items-center`}
                  >
                    <IoMdShareAlt style={{ width: "24px", height: "24px" }} />
                    <div className={Styles.shareCount}>12</div>
                  </div>
                </div>
                <div
                  className={`${Styles.footerRightSection} d-flex align-items-center`}
                >
                  <div
                    style={{
                      transform: `translateX(${array.length * 0}px)`,
                    }}
                    className={`${Styles.footerImgContainer} d-flex align-items-center`}
                  >
                    {array.map((element, index) => (
                      <div className="d-flex">
                        <div
                          key={index}
                          className={Styles.footerImgWrapper}
                          style={{
                            transform: `translateX(-${index * 10}px)`,
                          }}
                        >
                          <img
                            className={Styles.footerImg}
                            src={require("../../assets/images/Smart_looking teacher.png")}
                            alt={`footerImg${index}`}
                          />
                        </div>
                        {index === array.length - 1 ? (
                          <div
                            className="d-flex align-items-center justify-content-center"
                            style={{
                              transform: `translateX(-${index * 10}px)`,
                              fontFamily: "Montserrat",
                              fontStyle: "normal",
                              marginLeft: "5px",
                              fontWeight: 600,
                              fontSize: "12px",
                              lineHeight: "15px",
                              color: "#727272",
                            }}
                          >
                            {array.length} others
                          </div>
                        ) : null}
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
            <div onClick={() => {
              navigateTo("/Community/ViewPost");
            }} className={Styles.articleCard}>
              <div className={Styles.articleCardHeader}>
                <div className="d-flex gap-2">
                  <div className={Styles.articleCardImgContainer}>
                    <img
                      className={Styles.articleCardImg}
                      src={require("../../assets/images/Smart_looking teacher.png")}
                      alt="articleCardImg"
                    />
                  </div>
                  <div className={Styles.articleProfileContainer}>
                    <div className={Styles.nameContainer}>Aryan Brennan</div>
                    <div className={Styles.timeContainer}>45 minutes ago</div>
                  </div>
                </div>
                <div>
                  <BsThreeDots />
                </div>
              </div>
              <div className={Styles.articleContent}>
                What are few things to keep in mind while designing shoes with
                Split Leather ?
              </div>
              <div className={Styles.articleFooter}>
                <div
                  className={`${Styles.articleStatsContainer} d-flex gap-4 align-items-center`}
                >
                  <div
                    className={`${Styles.likesContainer} gap-2 d-flex align-items-center`}
                  >
                    <IoMdHeart
                      style={{
                        color: "#F65858",
                        width: "24px",
                        height: "24px",
                      }}
                    />
                    <div className={Styles.likesCount}>12</div>
                  </div>
                  <div
                    className={`${Styles.commentsContainer} gap-2 d-flex align-items-center`}
                  >
                    <BiMessageRoundedDetail
                      style={{ width: "24px", height: "24px" }}
                    />
                    <div className={Styles.commentsCount}>12</div>
                  </div>
                  <div
                    className={`${Styles.shareContainer} gap-2 d-flex align-items-center`}
                  >
                    <IoMdShareAlt style={{ width: "24px", height: "24px" }} />
                    <div className={Styles.shareCount}>12</div>
                  </div>
                </div>
                <div
                  className={`${Styles.footerRightSection} d-flex align-items-center`}
                >
                  <div
                    style={{
                      transform: `translateX(${array.length * 0}px)`,
                    }}
                    className={`${Styles.footerImgContainer} d-flex align-items-center`}
                  >
                    {array.map((element, index) => (
                      <div className="d-flex">
                        <div
                          key={index}
                          className={Styles.footerImgWrapper}
                          style={{
                            transform: `translateX(-${index * 10}px)`,
                          }}
                        >
                          <img
                            className={Styles.footerImg}
                            src={require("../../assets/images/Smart_looking teacher.png")}
                            alt={`footerImg${index}`}
                          />
                        </div>
                        {index === array.length - 1 ? (
                          <div
                            className="d-flex align-items-center justify-content-center"
                            style={{
                              transform: `translateX(-${index * 10}px)`,
                              fontFamily: "Montserrat",
                              fontStyle: "normal",
                              marginLeft: "5px",
                              fontWeight: 600,
                              fontSize: "12px",
                              lineHeight: "15px",
                              color: "#727272",
                            }}
                          >
                            {array.length} others
                          </div>
                        ) : null}
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
            <div onClick={() => {
              navigateTo("/Community/ViewPost");
            }} className={Styles.articleCard}>
              <div className={Styles.articleCardHeader}>
                <div className="d-flex gap-2">
                  <div className={Styles.articleCardImgContainer}>
                    <img
                      className={Styles.articleCardImg}
                      src={require("../../assets/images/Smart_looking teacher.png")}
                      alt="articleCardImg"
                    />
                  </div>
                  <div className={Styles.articleProfileContainer}>
                    <div className={Styles.nameContainer}>Aryan Brennan</div>
                    <div className={Styles.timeContainer}>45 minutes ago</div>
                  </div>
                </div>
                <div>
                  <BsThreeDots />
                </div>
              </div>
              <div className={Styles.articleContent}>
                What are few things to keep in mind while designing shoes with
                Split Leather ?
              </div>
              <div className={Styles.articleFooter}>
                <div
                  className={`${Styles.articleStatsContainer} d-flex gap-4 align-items-center`}
                >
                  <div
                    className={`${Styles.likesContainer} gap-2 d-flex align-items-center`}
                  >
                    <IoMdHeart
                      style={{
                        color: "#F65858",
                        width: "24px",
                        height: "24px",
                      }}
                    />
                    <div className={Styles.likesCount}>12</div>
                  </div>
                  <div
                    className={`${Styles.commentsContainer} gap-2 d-flex align-items-center`}
                  >
                    <BiMessageRoundedDetail
                      style={{ width: "24px", height: "24px" }}
                    />
                    <div className={Styles.commentsCount}>12</div>
                  </div>
                  <div
                    className={`${Styles.shareContainer} gap-2 d-flex align-items-center`}
                  >
                    <IoMdShareAlt style={{ width: "24px", height: "24px" }} />
                    <div className={Styles.shareCount}>12</div>
                  </div>
                </div>
                <div
                  className={`${Styles.footerRightSection} d-flex align-items-center`}
                >
                  <div
                    style={{
                      transform: `translateX(${array.length * 0}px)`,
                    }}
                    className={`${Styles.footerImgContainer} d-flex align-items-center`}
                  >
                    {array.map((element, index) => (
                      <div className="d-flex">
                        <div
                          key={index}
                          className={Styles.footerImgWrapper}
                          style={{
                            transform: `translateX(-${index * 10}px)`,
                          }}
                        >
                          <img
                            className={Styles.footerImg}
                            src={require("../../assets/images/Smart_looking teacher.png")}
                            alt={`footerImg${index}`}
                          />
                        </div>
                        {index === array.length - 1 ? (
                          <div
                            className="d-flex align-items-center justify-content-center"
                            style={{
                              transform: `translateX(-${index * 10}px)`,
                              fontFamily: "Montserrat",
                              fontStyle: "normal",
                              marginLeft: "5px",
                              fontWeight: 600,
                              fontSize: "12px",
                              lineHeight: "15px",
                              color: "#727272",
                            }}
                          >
                            {array.length} others
                          </div>
                        ) : null}
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
            <div onClick={() => {
              navigateTo("/Community/ViewPost");
            }} className={Styles.articleCard}>
              <div className={Styles.articleCardHeader}>
                <div className="d-flex gap-2">
                  <div className={Styles.articleCardImgContainer}>
                    <img
                      className={Styles.articleCardImg}
                      src={require("../../assets/images/Smart_looking teacher.png")}
                      alt="articleCardImg"
                    />
                  </div>
                  <div className={Styles.articleProfileContainer}>
                    <div className={Styles.nameContainer}>Aryan Brennan</div>
                    <div className={Styles.timeContainer}>45 minutes ago</div>
                  </div>
                </div>
                <div>
                  <BsThreeDots />
                </div>
              </div>
              <div className={Styles.articleContent}>
                What are few things to keep in mind while designing shoes with
                Split Leather ?
              </div>
              <div className={Styles.articleFooter}>
                <div
                  className={`${Styles.articleStatsContainer} d-flex gap-4 align-items-center`}
                >
                  <div
                    className={`${Styles.likesContainer} gap-2 d-flex align-items-center`}
                  >
                    <IoMdHeart
                      style={{
                        color: "#F65858",
                        width: "24px",
                        height: "24px",
                      }}
                    />
                    <div className={Styles.likesCount}>12</div>
                  </div>
                  <div
                    className={`${Styles.commentsContainer} gap-2 d-flex align-items-center`}
                  >
                    <BiMessageRoundedDetail
                      style={{ width: "24px", height: "24px" }}
                    />
                    <div className={Styles.commentsCount}>12</div>
                  </div>
                  <div
                    className={`${Styles.shareContainer} gap-2 d-flex align-items-center`}
                  >
                    <IoMdShareAlt style={{ width: "24px", height: "24px" }} />
                    <div className={Styles.shareCount}>12</div>
                  </div>
                </div>
                <div
                  className={`${Styles.footerRightSection} d-flex align-items-center`}
                >
                  <div
                    style={{
                      transform: `translateX(${array.length * 0}px)`,
                    }}
                    className={`${Styles.footerImgContainer} d-flex align-items-center`}
                  >
                    {array.map((element, index) => (
                      <div className="d-flex">
                        <div
                          key={index}
                          className={Styles.footerImgWrapper}
                          style={{
                            transform: `translateX(-${index * 10}px)`,
                          }}
                        >
                          <img
                            className={Styles.footerImg}
                            src={require("../../assets/images/Smart_looking teacher.png")}
                            alt={`footerImg${index}`}
                          />
                        </div>
                        {index === array.length - 1 ? (
                          <div
                            className="d-flex align-items-center justify-content-center"
                            style={{
                              transform: `translateX(-${index * 10}px)`,
                              fontFamily: "Montserrat",
                              fontStyle: "normal",
                              marginLeft: "5px",
                              fontWeight: 600,
                              fontSize: "12px",
                              lineHeight: "15px",
                              color: "#727272",
                            }}
                          >
                            {array.length} others
                          </div>
                        ) : null}
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className={`${Styles.paginationContainer} d-flex gap-3 align-items-center`}
          >
            <div className={Styles.paginationBtn}>
              <img
                src={require("../../assets/images/leftArrow.png")}
                alt="arrow-left"
                className={Styles.paginationBtnIcon}
              />
              <div className={Styles.paginationBtnText}>Previous</div>
            </div>
            <div className={`${Styles.paginationPageBtnContainer} d-flex`}>
              <div className={`${Styles.paginationPageBtn} active`}>1</div>
              <div className={Styles.paginationPageBtn}>2</div>
              <div className={Styles.paginationPageBtn}>3</div>
              <div className={Styles.paginationPageBtn}>4</div>
            </div>
            <div className={Styles.paginationBtn}>
              <div className={Styles.paginationBtnText}>Next</div>
              <img
                src={require("../../assets/images/rightArrow.png")}
                alt="arrow-right"
                className={Styles.paginationBtnIcon}
              />
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};
export const NewArticleList = () => {
  const navigateTo = (url) => {
    window.location.href = url;
  };
  const currentRoute = window.location.pathname;
  useState(() => {
    console.log("currentRoute", currentRoute);
  }, []);
  return (
    <div className={Styles.newArticleListContainer}>
      <div className={Styles.newArticleListHeaderTextContainer}>
        <div className={Styles.newArticleListHeaderText}>New Articles</div>
      </div>
      <div className={Styles.newArticleCardListContainer}>
        <div className={Styles.newArticleCard}>
          <div className={Styles.newArticleImgContainer}>
            <img
              className={Styles.newArticleImg}
              src={require("../../assets/images/Mask_group.png")}
              alt="newArticleImg"
            />
          </div>
          <div className={Styles.newArticleContent}>
            A Guide to Buying a Home in 2021
          </div>
        </div>
        <div className={Styles.newArticleCard}>
          <div className={Styles.newArticleImgContainer}>
            <img
              className={Styles.newArticleImg}
              src={require("../../assets/images/Mask_group.png")}
              alt="newArticleImg"
            />
          </div>
          <div className={Styles.newArticleContent}>
            A Guide to Buying a Home in 2021
          </div>
        </div>
        <div className={Styles.newArticleCard}>
          <div className={Styles.newArticleImgContainer}>
            <img
              className={Styles.newArticleImg}
              src={require("../../assets/images/Mask_group.png")}
              alt="newArticleImg"
            />
          </div>
          <div className={Styles.newArticleContent}>
            A Guide to Buying a Home in 2021
          </div>
        </div>
        <div className={Styles.newArticleCard}>
          <div className={Styles.newArticleImgContainer}>
            <img
              className={Styles.newArticleImg}
              src={require("../../assets/images/Mask_group.png")}
              alt="newArticleImg"
            />
          </div>
          <div className={Styles.newArticleContent}>
            A Guide to Buying a Home in 2021
          </div>
        </div>
        {currentRoute == "/Community/ArticleList" ? (
          <button
            onClick={() => {
              navigateTo("/Community/CreatePost");
            }}
            className={`btn ${Styles.btnPrimary}`}
          >
            Create Post
          </button>
        ) : (
          <button
            onClick={() => {
              navigateTo("/Community/ArticleList");
            }}
            className={`btn ${Styles.btnPrimary}`}
          >
            View feed
          </button>
        )}
      </div>
    </div>
  );
};
