import React from 'react';
import { Link } from "react-router-dom";
import { API_URL } from '../api';
import LoginBg from '../assets/images/bg.png';
import { useCookies } from '../hooks/useCookies';
export const Signup = () => {

    const cookies = useCookies();

    async function handleSubmit(e) {
        e.preventDefault();
        console.log(Object.fromEntries(new FormData(e.target).entries()))
        const response = await fetch(`${API_URL}/user/user/register`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                ...Object.fromEntries(new FormData(e.target).entries()),
                userType: "designer" 
            }),
        });

        const data = await response.json();
        if (response.ok) {
            cookies?.setCookie('userId', data.data.id, 365);
            cookies?.setCookie('userName',data.data.name,365);
            window.location.href = '/Community/Registration';
        }
    }

    
    return (
        <div className="signup_page">
            <div className="bg_inner">
                <img src={LoginBg} alt="" className="media_img" />
                <div className="card_success">
                    <div className="bg_inner bg_Create">
                        <h5>Create Your Account</h5>
                        <form className="form" onSubmit={handleSubmit}>
                            <div className="frm_grp">
                                <label htmlFor="name">Full Name</label>
                                <input required type="text" name="name" id="name" className="form-control" placeholder="John Thomas" />
                            </div>
                            <div className="frm_grp">
                                <label htmlFor="email">Email ID</label>
                                <input required type="email" name="email" id="email" className="form-control" placeholder="johnthomas@gmail.com" />
                            </div>

                            <div className="frm_grp">
                                <label htmlFor="phone">Mobile Number</label>
                                <input
                                    required
                                    type="text"
                                    name="phone"
                                    id="phone"
                                    inputMode='numeric'
                                    minLength={10}
                                    maxLength={10}
                                    className="form-control"
                                    placeholder="+1 665 183 4627"
                                />
                            </div>

                            <div className="frm_frog">
                                <div className="otp_grp">
                                    <input type="text" placeholder="*" className="form-control" />
                                    <input type="text" placeholder="*" className="form-control" />
                                    <input type="text" placeholder="*" className="form-control" />
                                    <input type="text" placeholder="*" className="form-control" />
                                </div>
                                <div className="pass_rest">
                                    00:14 <a href="#">Resend OTP</a>
                                </div>
                            </div>
                            <div className="btn_grp text-center">
                                <button className="btn">SIGN UP</button>
                            </div>
                            <p className="text_botm">Already have an account? <Link to={"/Login"}>Login</Link></p>


                        </form>


                    </div>

                </div>
            </div>
        </div>
    );
};
