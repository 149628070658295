import React, { useMemo, useState } from "react";
import styles from "./FrameComponent.module.css";
import image from "../public/underline-1.svg";
export const FrameComponent = ({ howItWorks, underline, propWidth }) => {
  console.log(underline);
  const frameDivStyle = useMemo(() => {
    return {
      width: propWidth,
    };
  }, [propWidth]);
  return (
    <div className={styles.headingWrapper} style={frameDivStyle}>
      <div className={styles.heading}>
        <h1 className={styles.howItWorks}>{howItWorks}</h1>
        <img className={styles.underlineIcon} alt="" src={underline} />
      </div>
    </div>
  );
};
