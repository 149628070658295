import React, { useEffect, useState } from 'react';
import ContextApi from './ContextApi'; 
import { useCookies } from '../hooks/useCookies';

const Context = ({ children }) => {
    const [accountType, setAccountType] = useState("");
    const[userName, setUsername] = useState("");
    const cookies = useCookies();

    useEffect(() => {
        if (accountType) {
            cookies.setCookie("accountType", accountType);
        }
    }, [accountType]);

    return (
        <div>
            <ContextApi.Provider value={{ accountType, setAccountType,userName, setUsername }}>
                {children}
            </ContextApi.Provider>
        </div>
    );
};

export default Context;