import React, { useState } from 'react';
import {FrameComponent} from "./FrameComponent";
import underline from "../public/underline-1.svg";
import styles from './priceDetails.module.css'; // Import your CSS module
import menufecturePrice from '../../../../assets/images/menufecturePrice.jpg';
import suplierPrice from '../../../../assets/images/suplierPrice.jpg';
import deginerPrice from '../../../../assets/images/deginerPrice.jpg';
import coin from '../../../../assets/images/coin.png';
const PriceModel = () => {
  const [activeButton, setActiveButton] = useState(1); // State to keep track of active button
  const images = [ deginerPrice,suplierPrice,menufecturePrice, ]; // Array of image URLs

  const handleButtonClick = (buttonNumber) => {
    setActiveButton(buttonNumber);
  };

  return (
    <div>
        <div className={styles.subscription}>
        <FrameComponent
          howItWorks="Subscription Plans"
          underline={underline}
          propWidth={400}
        />
        </div>
     
    <div className={styles.priceModelContainer}>
      
      <div className={styles.contentContainer}>
        
        <div className={`${styles.content} ${activeButton === 1 ? styles.active : ''}`}>
        <div >
            <img className={styles.coinpng} src={coin}/>
        </div>
            <h1 className={styles.monthlyPrice}>
                $299/ <span style={{fontSize: '20px'}}>Month</span>
            </h1>
            <div className={styles.contentline}>
  {[
    'AI-Driven Design Assistance',
    'AI Trend Analysis & exclusive materials',
    'Virtue Product Preview & 3D Printing',
    'AI/ML - Driven Matchmaking',
    'Access to verified Manufactures and Suppliers'
  ].map((line, index) => (
    <p className={styles.ticks} style={{ marginBottom: '3px' }} key={index}>&#10004; {line}</p>
  ))}
</div>

          
      

  <div>
    <p className={styles.transections}></p>
  </div>
  <div className={styles.continue}>
    <button className={styles.buttoncontinue} >Continue</button>
  </div>
        </div>

        <div className={`${styles.content} ${activeButton === 2 ? styles.active : ''}`}>
        <div style={{marginBottom:"55px"}} >
            <img className={styles.coinpng} src={coin}/>
        </div>
            {/* <h1 className={styles.monthlyPrice}>
                $299/ <span style={{fontSize: '20px'}}>Month</span>
            </h1> */}
            <div className={styles.contentline}>
                
            {[
                'Visibility & Reach',
                'Facilitate Connection with Designers',
                'Efficient Marketing Channels',
                'Higher Order Volume',
            ].map((line, index) => (
                <p className={styles.ticks} key={index}>&#10004; {line}</p>
            ))}
            </div>
          
      

  <div>
    <p className={styles.transections}>2.5% Transaction Fee for Each Successful Transaction through 
the Platform</p>
  </div>
  <div className={styles.continue}>
    <button className={styles.buttoncontinue} >Continue</button>
  </div>
        </div>

        <div className={`${styles.content} ${activeButton === 3 ? styles.active : ''}`}>
        <div  style={{marginBottom:"55px"}} >
            <img className={styles.coinpng} src={coin}/>
        </div>
            {/* <h1 className={styles.monthlyPrice}>
                $399/ <span style={{fontSize: '20px'}}>Month</span>
            </h1> */}
            <div className={styles.contentline}>
                
            {[
                'Visibility & Reach',
                'Facilitate Connection with Designers',
                'Efficient Marketing Channels',
                'Higher Order Volume',
            ].map((line, index) => (
                <p className={styles.ticks} key={index}>&#10004; {line}</p>
            ))}
            </div>
          
      

  <div>
    <p className={styles.transections}>2.5% Transaction Fee for Each Successful Transaction through 
the Platform</p>
  </div>
  <div className={styles.continue}>
    <button className={styles.buttoncontinue} >Continue</button>
  </div>
        </div>

      </div>
      <div className={styles.rightside}>
      <div className={styles.buttonsContainer}>
        <button
          className={`${styles.priceButton} ${activeButton === 1 ? styles.active : ''}`}
          onClick={() => handleButtonClick(1)}
        >
          Designer
        </button>
        <button
          className={`${styles.priceButton} ${activeButton === 2 ? styles.active : ''}`}
          onClick={() => handleButtonClick(2)}
        >
         Supplier
        </button>
        <button
          className={`${styles.priceButton} ${activeButton === 3 ? styles.active : ''}`}
          onClick={() => handleButtonClick(3)}
        >
          Manufacturer
        </button>
      </div>
      <div className={styles.picContainer}>
        <img
          src={images[activeButton - 1]}
          alt={`Pic ${activeButton}`}
          className={styles.pic}
        />
      </div>
      </div>
     
    </div>
    </div>
  );
};

export default PriceModel;
