
import React from 'react';
import LoginBg from '../../assets/images/bg.png';
import { API_URL } from '../../api';
import { Link } from 'react-router-dom';


export const AdminLogin = () => {
    async function handleLogin(e) {
        e.preventDefault();

        try {
            const response = await fetch(`${API_URL}/admin/login`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(Object.fromEntries(new FormData(e.target).entries())),
            });
            const data = await response.json();
            if (response.ok) {
                sessionStorage.setItem('adminId', data.id);
                window.location.assign('/Admin/Dashboard');
            }
        } catch (error) {
            console.log(error);
        }
    }
    return (
        <>
            <div className="signup_page">
                <div className="bg_inner">
                    <img src={LoginBg} className="media_img" />
                    <div className="card_success">
                        <div className="bg_inner bg_Create">
                            <h5>Admin Login</h5>
                            <form onSubmit={handleLogin} className="form">
                                <div className="frm_grp">
                                    <label for="">Email ID</label>
                                    <input type="text" name="email" id="" className="form-control" placeholder="admin@pllohide.ca " />
                                </div>
                                <div className="frm_frog">
                                    <div className="otp_grp">
                                        <input type="text" placeholder="*" className="form-control" />
                                        <input type="text" placeholder="*" className="form-control" />
                                        <input type="text" placeholder="*" className="form-control" />
                                        <input type="text" placeholder="*" className="form-control" />
                                    </div>
                                    <div className="pass_rest">
                                        00:14 <a href="#">Resend OTP</a>
                                    </div>
                                </div>
                                <div className="btn_grp text-center">
                                    <button className="btn">Login</button>
                                </div>
                            </form>


                        </div>

                    </div>
                  <Link to = '/Login'>
                    <a className="btn_home" to={"/Community"}>Home</a>
                  </Link>
                 
                </div>
            </div>
        </>
    );
};
