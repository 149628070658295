
    import React, { useState, useEffect, useContext } from 'react';
    // import LoginBg from '../../assets/images/bg.png';
    import { Link, useNavigate } from 'react-router-dom';
    import ContextApi from '../../context/ContextApi';
    import axios from 'axios'; 
    import { useCookies } from '../../hooks/useCookies';
    import { API_URL } from '../../api';
    // import React from 'react';
    import LoginBg from '../../assets/images/bg.png';
    import Designer from '../../assets/images/Designer.png';
    import Supplier from '../../assets/images/Supplier.png';
    import Manufacturer from '../../assets/images/Manufacturer.png';
    
    export const SupplierFlowCreateAccount = () => {
        const { accountType } = useContext(ContextApi);
        const [otp, setOtp] = useState('');
        const [otpInputs, setOtpInputs] = useState(['', '', '', '']);
        const [fullName, setFullName] = useState('');
        const [email, setEmail] = useState('');
        const [mobileNumber, setMobileNumber] = useState('');
        const [timer, setTimer] = useState(60);
         const cookies = useCookies();
         const navigate = useNavigate()


        const generateOTP = () => {
            const generatedOTP = Math.floor(1000 + Math.random() * 9000);
            setOtp(generatedOTP.toString());
            setOtpInputs(generatedOTP.toString().split(''));
            setTimer(60);
        };
    
        const handleOtpInputChange = (index, value) => {
            const updatedOtpInputs = [...otpInputs];
            updatedOtpInputs[index] = value;
            setOtpInputs(updatedOtpInputs);
        };
    
        const handleSignUp = async () => {
            const enteredOtp = otpInputs.join('');
            if (enteredOtp === otp && fullName && email && mobileNumber) {
                try {
                    const response = await axios.post(`${API_URL}/user/user/register`, {
                        name: fullName,
                        email: email,
                        userType: accountType,
                        phone: mobileNumber
                    });
                    const data = response.data;
                    console.log(data);
                    if (response.status === 200) { 
                        cookies?.setCookie('userId', data.data.id, 365);
                        cookies?.setCookie('userName',data.data.name,365);
                        console.log(cookies);
                        navigate('/SupplierFlow/SupplierFlowRegistration'); 
                    } else {
                       
                        alert('Failed to create user. Please try again later.');
                    }
                } catch (error) {
                   
                    alert('Failed to create user. Please try again later.');
                    console.log(error);
                }
            } else {
                alert('Please fill all fields correctly.');
            }
        };
        
    
        useEffect(() => {
            const countdown = setInterval(() => {
                if (timer > 0) {
                    setTimer(timer - 1);
                }
            }, 1000);
    
            return () => clearInterval(countdown);
        }, []);
    
        return (
            <div className="signup_page">
                <div className="bg_inner">
                    <img src={LoginBg} className="media_img" alt="Login Background" />
                    <div className="card_success">
                        <div className="bg_inner bg_Create">
                            <h5>Create Your Account</h5>
                            <div className="form">
                                <div className="frm_grp">
                                    <label htmlFor="">Full Name</label>
                                    <input type="text" value={fullName} onChange={(e) => setFullName(e.target.value)} className="form-control" placeholder="John Thomas" required />
                                </div>
                                <div className="frm_grp">
                                    <label htmlFor="">Email ID</label>
                                    <input type="email" value={email} onChange={(e) => setEmail(e.target.value)} className="form-control" placeholder="johnthomas@gmail.com" required />
                                </div>
                                <div className="frm_grp">
                                    <label htmlFor="">Mobile Number</label>
                                    <input type="tel" value={mobileNumber}   minLength={10}
                                    maxLength={10} onChange={(e) => setMobileNumber(e.target.value)} className="form-control" placeholder="+1 665 183 4627" required />
                                </div>
                                <div className="frm_frog">
                                    <div className="otp_grp">
                                        {otpInputs.map((value, index) => (
                                            <input key={index} type="text" maxLength="1" value={value} onChange={(e) => handleOtpInputChange(index, e.target.value)} className="form-control" />
                                        ))}
                                    </div>
                                    <div className="pass_rest">
                                        {timer > 0 ? (
                                            <>
                                                {`${String(Math.floor(timer / 60)).padStart(2, '0')}:${String(timer % 60).padStart(2, '0')}`}{' '}
                                                <Link to="#" onClick={generateOTP}> Resend OTP</Link>
                                            </>
                                        ) : (
                                            <Link to="#" onClick={generateOTP}>Send OTP</Link>
                                        )}
                                    </div>
                                </div>
                                <div className="btn_grp text-center">
                                    <button className="btn" onClick={handleSignUp}>SIGN UP</button>
                                </div>
                                <p className="text_botm">Already have an account? <Link to="/ManufacturerFlow/Login">Login</Link></p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    };
    
    
