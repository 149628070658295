import React, { useState } from 'react';
import { Button } from "@mui/material";
import { Link, NavLink, useNavigate } from "react-router-dom";
import LoginBg from '../../assets/images/bg.png';
import axios from 'axios';
import { useCookies } from "../../hooks/useCookies";
import { API_URL } from '../../api';

export const LoginManufacturer = () => {
    const { setCookie } = useCookies();
    const navigate = useNavigate();
    const [phone, setPhone] = useState('');
    const [otp, setOtp] = useState('');
    const [error, setError] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    const generateRandomOTP = () => {
        const otp = Math.floor(1000 + Math.random() * 9000);
        setOtp(otp.toString());
    };

    const handleLogin = async () => {
        setIsLoading(true);
        try {
            const response = await axios.post(`${API_URL}/user/user/login`, { phone });
            console.log('Login successful:', response.data);
            setCookie('userId', response.data.id, 365);
            if (response.data.userType === 'designer') {
                navigate('/DesignerProfile/TheProfile');
            }
            else if (response.data.userType === 'supplier') {
                navigate('/SupplierProfile');
            }
            else {
                navigate('/ManufacturerProfile');
            }
        } catch (error) {
            alert("User Not Found");
            console.error('Login error:', error.message);
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <>
            <div className="signup_page">
                <div className="bg_inner">
                    <img src={LoginBg} alt="" className="media_img" />
                    <div className="card_success">
                        <div className="bg_inner bg_Create">
                            <h5 className="text-left">Welcome Back</h5>
                            <div className="form">
                                <div className="frm_grp">
                                    <label htmlFor="phone">Mobile Number</label>
                                    <input type="text" name="phone" id="phone" className="form-control" placeholder="+1 665 183 4627" value={phone} onChange={(e) => setPhone(e.target.value)} />
                                </div>
                                <div className="frm_frog">
                                    <div className="otp_grp">
                                        <input type="text" placeholder="*" className="form-control" value={otp[0] || ''} readOnly />
                                        <input type="text" placeholder="*" className="form-control" value={otp[1] || ''} readOnly />
                                        <input type="text" placeholder="*" className="form-control" value={otp[2] || ''} readOnly />
                                        <input type="text" placeholder="*" className="form-control" value={otp[3] || ''} readOnly />
                                    </div>
                                    <div className="pass_rest">
                                        00:14  <Link to="#" onClick={generateRandomOTP}> Resend OTP</Link>
                                    </div>
                                </div>
                                <div className="btn_grp text-center">
                                    <button className="btn" onClick={handleLogin} disabled={isLoading}>LOGIN</button>
                                </div>
                                <p className="text_botm">You don’t have an account ? <Link to={"/Signup"}>Signup</Link></p>
                            </div>
                        </div>

                    </div>

                </div>
            </div>
        </>
    );
};
