import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom'; 
import LoginBg from '../../assets/images/bg.png';
import Designer from '../../assets/images/Designer.png';
import Supplier from '../../assets/images/Supplier.png';
import Manufacturer from '../../assets/images/Manufacturer.png';
import ContextApi from '../../context/ContextApi'; 

export const CreateAccount = () => {
    const { accountType, setAccountType } = useContext(ContextApi);
    const navigate = useNavigate(); 
    const handleAccountType = (type) => {
        setAccountType(type); 
        navigate('/ManufacturerFlow/ChooseAccount'); 
    };
    return (
        <>
            <div className="signup_page">
                <div className="bg_inner">
                    <img src={LoginBg} className="media_img" />
                    <div className="card_success">
                        <div className="bg_inner bg_account">
                            <h5>Choose your account</h5>
                            <div className="list_account">
                                <label htmlFor="Designer" onClick={() => handleAccountType('designer')}>
                                    <input type="radio" name="account" id="Designer" />
                                    <span>
                                        <div className="card_acc"> <img src={Designer} alt="" /> </div>
                                        <p>Designer</p>
                                    </span>
                                </label>
                                <label htmlFor="Supplier" onClick={() => handleAccountType('supplier')}>
                                    <input type="radio" name="account" id="Supplier" />
                                    <span>
                                        <div className="card_acc"> <img src={Supplier} alt="" /> </div>
                                        <p>Supplier</p>
                                    </span>
                                </label>
                                <label htmlFor="Manufacturer" onClick={() => handleAccountType('manufacturer')}>
                                    <input type="radio" name="account" id="Manufacturer" />
                                    <span>
                                        <div className="card_acc"> <img src={Manufacturer} alt="" /> </div>
                                        <p>Manufacturer</p>
                                    </span>
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
